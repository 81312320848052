<template>
    <Dialog header="Join Course" v-model:visible="display" :modal="true" class='join-group-modal' :draggable='false'>
        <div class="container">
            <Dropdown class="course-selector" v-model="selectedCourse" :options="courses" optionLabel="name" placeholder="Select Course" :loading="loadingCourses">
            </Dropdown>
            <Dropdown class="cohort-selector" v-model="selectedCohort" :options="cohorts" placeholder="Select Cohort" :loading="loadingCohorts">
                 <template #value="slotProps">
                    <div v-if="slotProps.value" >
                        <div>{{getCohortLabel(slotProps.value) }}</div>
                    </div>
                    <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                </template>
                <template #option="slotProps">
                    
                        <div :disabled="slotProps.option.full">{{ getCohortLabel(slotProps.option)}}</div>
                    
                </template>
            </Dropdown>
            <div v-if="selectedCohort?.accessCode" style="width:100%;display:flex;align-content:center;align-items:center;margin-top:5px;">
                <label for="regAccessCode" class='input-label' v-tooltip.top="'This cohort requires an access code.'">Access Code:</label>
                <InputText id="regAccessCode" class="input-text" v-model="accessCode" style="margin-left:auto;"/>
            </div>

            <div v-if="selectedCourse?.courseProduct" style="margin:10px;">
                    <p>There is a fee of <strong>${{selectedCourse?.courseProduct.price}} {{(selectedCourse?.courseProduct.currencyId == 1? "CAD": "USD")}}</strong> to join this course.</p>
                    
            </div>
        </div>

        <template #footer>
            <Button class='join-button' label='Join' :disabled="joinDisabled" @click='joinCourse()' :loading="checkingOut"/>
            <Button class='cancel-button' label='Cancel' @click='cancel()'/>
        </template>
    </Dialog>

</template>

<script>
import Dialog from 'primevue/dialog';

import GroupService from '../../../service/GroupService';
import PaymentService from '../../../service/PaymentService';
import format from 'date-fns/format';
import Dropdown from 'primevue/dropdown';
import EventBus from '../../../event-bus';

export default {
    name: 'SelectCourseAndCohortModal',
    emits: ['education-group-joined'],
    props: {
        selectedGroup: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            display: false,
            selectedCourse: null,
            selectedCohort: null,
            loadingCourses: false,
            loadingCohorts: false,
            courses: [],
            cohorts: [],
           courseAndCohortSelected: false,
           checkingOut: false,
           accessCode: null,
           preSelectedCohortId: null,
            
        }
    },
    components: {
        Dialog, 
        Dropdown,
    },

    watch: {
        selectedCourse(newVal, oldVal) {//eslint-disable-line
            if( newVal && newVal != oldVal && this.display ) {
                this.selectedCohort = null;
                this.courseAndCohortSelected = false;
                this.loadCohorts(newVal);
            }
        },

        selectedCohort(newVal, oldVal) {//eslint-disable-line
        
            if( newVal && this.display ) {

                this.courseAndCohortSelected = true;
            }
        }
    },

    computed: {
        joinDisabled() {
            return !this.courseAndCohortSelected;
        }
    },

   
    methods: {
        getCohortLabel(cohort) {
            if( cohort.cohortName ) {
                return cohort.cohortName+ " starts " + format(new Date(cohort.startDate), 'yyyy-MM-dd') +" and ends " + format(new Date(cohort.endDate), 'yyyy-MM-dd')
            }
            else {
                return "Cohort starts " + format(new Date(cohort.startDate), 'yyyy-MM-dd') +" and ends " + format(new Date(cohort.endDate), 'yyyy-MM-dd')
            }
        },

        loadCohorts(course) {
            console.log("loading cohorts for coures:", course);
            this.loadingCohorts = true;
            if( this.preSelectedCohortId ){
                GroupService.getCourseCohort(course.groupId, course.courseId, this.preSelectedCohortId).then(resp => {
                    if( resp.data.status == "success") {
                    
                        this.cohorts  = [resp.data.cohort];
                        this.selectedCohort = this.cohorts[0];
                        
                    }
                    else {
                        console.log("error getting preselected cohort: "+ resp.data.message);
                    }
                    this.loadingCohorts = false;
                }).catch(err => {
                    console.log("unexpected error getting preselected cohort", err)
                    this.loadingCohorts = false;
                });
            }
            else {
                GroupService.getCourseCohorts(course).then(resp => {
                    if( resp.data.status == "success") {
                    
                        this.cohorts = resp.data.nextCohorts;
                        
                    }
                    else {
                        console.log("error getting open cohorts: "+ resp.data.message);
                    }
                    this.loadingCohorts = false;
                }).catch(err => {
                    console.log("unexpected error getting open cohorts", err)
                    this.loadingCohorts = false;
                });
            }

        },
        loadCourses(group) {
            this.loadingCourses = true;
            GroupService.getGroupCourses(group.groupId).then(resp => {
                if (resp.data.status == "success") {
               
                    if (resp?.data?.courses?.length > 0 ) {
                        this.courses = resp.data.courses;
                    }
               
                }
                else {
                    console.error("error getting group courses :" + resp.data.message)
                }
                this.loadingCourses = false;
            });
        },
        open(selectedCourse, preSelectedCohortId) {
            this.selectedCourse = selectedCourse;
            this.selectedCohort = null;
            this.preSelectedCohortId = preSelectedCohortId;
            this.courseAndCohortSelected = false;
            this.courses = [];
            this.cohorts = [];
            this.display = true;
            this.checkingOut = false;
            this.accessCode = null;
            if( this.selectedCourse == null ){
                this.loadCourses(this.selectedGroup);
            }
            else {
                this.courses = [this.selectedCourse];
                this.loadCohorts(this.selectedCourse);
            }
        },

        cancel() {
            this.display = false;
            this.courses = [];
            this.cohorts = [];
            this.checkingOut = false;
            this.accessCode = null;
            this.preSelectedCohortId = null;
        },

        joinCourse() {
            this.checkingOut = true;
            
            if( this.selectedCourse.courseProduct){
                let req = {
                    groupId: this.selectedGroup.groupId,
                    courseId: this.selectedCourse.courseId,
                    courseCohortId: this.selectedCohort.cohortId,
                    productId: this.selectedCourse.courseProduct.productId,
                    accessCode: this.accessCode,
                }
                
                PaymentService.createGroupCourseRegistrationCheckoutSession(req).then(resp => {
                    if (resp.data.status === 'success') {
                        sessionStorage.setItem('showTourPrompt', false);
                        EventBus.emit('track-start-join-course', this.selectedCourse);
                        window.location.href = resp.data.url;
                    }else if( resp.data.message == 'Not Authorized.') {
                                this.$toast.add({ severity: 'error', summary: `You are not authorized to join this group.`, life: 3500, group: 'center'  });
                    }
                    else if( resp.data.message == 'Incorrect access code.') {
                        this.$toast.add({ severity: 'error', summary: `The access code you provided is incorrect.`, life: 3500, group: 'center'  });
                    }
                    else if( resp.data.message == 'Cohort Full.'){
                         this.$toast.add({ severity: 'error', summary: `Sorry the cohort you selected is currently full.`, life: 3500, group: 'center'  });
                    }
                    else {
                        this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: resp.message, life: 5000,  group: 'center'  });
                    }
                    this.checkingOut = false;
                })
            }
            else {
               
                 GroupService.joinPrivateOrFreeEducationGroup(this.selectedGroup.groupId, this.selectedCourse.courseId,this.selectedCohort.cohortId, this.accessCode).then(resp => {
                        if( resp.data.status === "success") {
                           
                           this.$emit('education-group-joined', resp.data.group);
                           this.checkingOut = false;
                           this.cancel();
                        }
                        else {
                            console.log("error joinging group "+ resp.data.message);
                            if( resp.data.message == 'Not Authorized') {
                                this.$toast.add({ severity: 'error', summary: `You are not authorized to join this group.`, life: 3500, group: 'center' });
                            }
                            else if( resp.data.message == 'Incorrect access code') {
                                this.$toast.add({ severity: 'error', summary: `The access code you provided is incorrect.`, life: 3500, group: 'center' });
                            }
                            else if( resp.data.message == 'Cohort Full') {
                                this.$toast.add({ severity: 'error', summary: `The selected cohort is full.`, life: 3500, group: 'center' });
                            }
                            else if( resp.data.message == 'Membership Closed') {
                                this.$toast.add({ severity: 'error', summary: `The access code you provided is incorrect.`, life: 3500,group: 'center'  });
                            }
                            else {
                                this.$toast.add({ severity: 'error', summary: `An unexpected error occurred while joining group.  Please try again or contact support@invrs.com.`, life: 3500,group: 'center'  });
                            }
                            this.checkingOut = false;
                        }
                    })
            }
            
            
        },

    },
}
</script>

<style>

.join-group-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
    border-radius: 16px;
}

.join-group-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.join-group-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.join-group-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    padding: 24px 16px;
}

.join-group-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>
.container {
    display: flex;
    flex-direction: column;
}


.course-selector {
    margin: 5px;
}
.cohort-selector {
    margin: 5px;
}

</style>